@font-face {
    font-family: 'DDIN';
    src: url(../fonts/D-DIN.ttf);
}

.money {
    font-family: DDIN;
    font-size: 14px;
}

.ant-pro-table-list-toolbar-right {
    float: right;
}

.ColFontBlod {
    font-weight: 500;
    font-size: 14px;
    line-height: 3rem;
}

.FontBlod {
    font-weight: 500;
    font-size: 14px;
}

.order_invoice_items ant-card-body {
    padding-top: 24px;
}

.order_ininvoice_table {
    background-color: #2b3e50;
}

.order_ininvoice_table .ant-card,
.order_ininvoice_table .ant-table,
.order_ininvoice_table .ant-table-summary {
    background-color: #2b3e50;
    color: #ebebeb;
}

.order_ininvoice_table .ant-table-row:hover {
    border: 1px solid red;
}

.order_ininvoice_table .ant-table-thead .ant-table-cell {
    background-color: #5bc0de;
    color: #ebebeb;
    font-size: 11px;
}

.order_ininvoice_table .ant-card-body {
    padding: 0;
    margin-bottom: 20px;
}

.order_ininvoice_table .ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: #2b3e50;
}

.order_ininvoice_table .ant-empty-description {
    color: #ebebeb;
}

.order_ininvoice_table .ant-empty {
    margin: 0;
}

.order_ininvoice_table .ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background-color: #2b3e50;
}

.order_ininvoice_table .ant-empty {
    display: none;
}

.order_ininvoice_table .ant-pro-card-body {
    padding: 0;
}

.define-table {
    border-collapse: collapse;
    border-spacing: 0;
    border-left: 1px solid #888;
    border-top: 1px solid #888;
}

.define-table th,
.define-table td {
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
    padding: 5px 15px;
}

.define-table th {
    font-weight: bold;
    background: #ccc;
}

.fc-popover-title {
    color: #333;
}

@media (min-width: 1200px) {
    .aoi_header>.container {
        width: 98%;
    }
}

.fc-daygrid-event-harness>.fc-daygrid-event {
    text-decoration: auto;
}

.fc-daygrid-event {
    padding: 10px;
}

.OrderImages>.ant-upload-wrapper>.ant-upload-list>.ant-upload-list-item-container {
    border: 1px dashed #333;
    height: 50px;
    width: 50px;
}

.OrderImages>.ant-upload-picture-card-wrapper>.ant-upload-list>.ant-upload.ant-upload-select-picture-card {
    width: 50px;
    height: 50px;
}

.BackProductImages>.ant-upload-wrapper>.ant-upload-list>.ant-upload-list-item-container {
    border: 1px dashed #333;
    height: 30px;
    width: 30px;
}

.BackProductImages>.ant-upload-picture-card-wrapper>.ant-upload-list>.ant-upload.ant-upload-select-picture-card {
    width: 30px;
    height: 30px;
}

.BackProductImages>.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    width: 50px;
    height: 50px;
}

.BackProductImages>.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-select {
    width: 50px;
    height: 50px;
}

.ant-image-mask-info {
    font-size: 10px;
}
.ant-pro-card-header {
    background-color: #2b3e50;
}
.ant-pro-card .ant-pro-card-header {
    padding: 0px;
}
.ant-pro-card .ant-pro-card-title {
    font-size: 14px;
    color: #FFF;
}
.ant-pro-card-body {
    background-color: #2b3e50;
}

.delivery .ant-pro-card-body {
    background-color: #FFF;
}

/* .ant-dropdown .ant-dropdown-menu, .css-dev-only-do-not-override-sck529.ant-dropdown-menu-submenu .ant-dropdown-menu {
    background-color: #2b3e50;
} */
.waybill_item .ant-pro-card-body {
    padding: 0;
}

.ant-table-tbody tr .ant-table-cell {
    border-bottom-color: rgb(128, 128, 128, 0.6);
}

.teamTab .ant-pro-card-body {
    padding: 0px;
}

.teamTab .ant-tabs-content-holder .ant-pro-table .ant-select-selection-item {
    color: #FFF;
}

.ant-table-wrapper .ant-table-filter-trigger.active {
    color: #2F3E4E;
}

.inquriy_statistics_table .ant-table-row {
    font-size: 10px;
}

.inquriy_statistics_table .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr>td {
    padding: 2px 5px;
}

.inquriy_statistics_table .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-thead>tr>th {
    padding: 2px 5px;
    font-size: 12px;
    font-weight: 600;
}

.InquirieOverview .ant-pro-card-body {
    padding: 0px;
}
.ant-pro-card.ant-pro-card-size-small .ant-pro-card-body {
    padding: 0px;
}

.InquirieOverview .ant-pro-statistic-card .ant-pro-card-body {
    padding: 2px;
}

.InquiriesTable .ant-input-group-addon {
    display: none;
}
.whiteTable tbody td {
    border: 1px solid black;
}
.hide_empty_row .ant-table-tbody .ant-table-placeholder .ant-table-cell {
    display: none;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-expanded-row-fixed {
    background-color: #2b3e50;
}
.InquiriesTable table tr .ant-table-cell {
    padding: 0px;
}
.ClientAddress .ant-pro-card .ant-pro-card-body{
    padding: 0px;
}
.ClientAddress .ant-pro-table-list-toolbar-container{
    padding-bottom: 0px;
}
.hideSearchIcon .ant-pro-table-list-toolbar-search .ant-input-wrapper 
.ant-input-group-addon
{
    display: none;
}
.boxHeader {
    border: 1px solid red;
}

.BatchEditProduct {
    padding: 0px;
}
.BatchEditProduct > .ant-pro-card > .ant-pro-card-body {
    padding: 0px;
}
.BatchEditProduct .ant-pro-table-list-toolbar {
    display: none;
}
.BatchEditProduct .ant-spin-container > .ant-table {
    border-radius: 0px;
}
.BatchEditProduct .ant-pro-table-alert {
    display: none;
}

.table > thead > tr.info > th {
    border: 0px;
    position: sticky;
    top: 0;
    z-index: 900;
}
/* 覆盖 antd 样式 */
.ant-image-preview-operations-wrapper .ant-image-preview-switch-left {
    margin-left: 21%;
    font-size: 100px;
    width: 100px;
    height: 100px;
    border-radius: 0px;
    background: rgba(0, 0, 0, 0.8);
}
.ant-image-preview-operations-wrapper .ant-image-preview-switch-right {
    margin-right: 21%;
    font-size: 100px;
    width: 100px;
    height: 100px;
    border-radius: 0px;
    background: rgba(0, 0, 0, 0.8);
}
.ant-image-preview-operations-wrapper .ant-image-preview-footer .ant-image-preview-operations {
    background: rgba(0, 0, 0, 0.9);
}
.ant-image-preview-switch-left .anticon svg, .ant-image-preview-switch-right .anticon svg
{
    font-size: 50px;
}
.ant-input {
    border-radius: 0px;
}
.QuicklySetPrice .ant-input-group .ant-input-group-addon {
    padding: 0px;
    border-width: 0px;
}
.QuicklySetPrice .ant-btn-compact-item{
    border-radius: 0px;
}
.QuicklySetPrice button{
    border-radius: 0px;
}
.PaymentsStatisticCard .ant-card .ant-card-body {
    padding: 8px;
}
.aoi_header .nav>li>a {
    padding: 10px 12px;
}
.UnitPrice .ant-input-group-addon {
    min-width: 25px;
}
.form-group {
    margin-bottom: 10px;
}
